import { Box, css, Flex, Image, Input, Select } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { Button } from '../components/button';
import { InputWarning } from '../components/InputWarning';
import { PageLayout } from '../components/pageLayout';

interface Props {}

export const query = graphql`
	query {
		prismic {
			allBecome_a_customers {
				edges {
					node {
						hero_image
						subtitle
						title
					}
				}
			}
		}
	}
`;

const StyledInput = styled(Input)`
	background: #f7fafc;
	color: #222b45;
	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #a0aec0;
		opacity: 1; /* Firefox */
	}
	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #a0aec0;
	}
	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #a0aec0;
	}
	border: none;
	margin-bottom: 18px;
`;

const signUp = ({ data }: Props) => {
	const prismicContent = data.prismic!.allBecome_a_customers!.edges[0]!.node;
	console.log(prismicContent);

	const [status, setStatus] = useState('');
	const [error, setError] = useState('');

	// This is linked up with Formspree.io - a great email form service
	const submitForm = async (e) => {
		e.preventDefault();
		const form = e.target;
		console.log(form);
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			console.log(xhr);
			if (xhr.status === 200) {
				form.reset();
				setStatus('SUCCESS');
			} else {
				setStatus('ERROR');
				setError(xhr.response);
			}
		};
		xhr.send(data);
	};

	return (
		<PageLayout>
			<Flex flexDir="column">
				<Box w="100">
					<Flex
						flexDir={{ base: 'column', lg: 'row' }}
						w="100%"
						bg="#f7f0e3"
						alignItems="center"
					>
						<Image
							src={prismicContent.hero_image.url}
							mt={{ base: 20, lg: 0 }}
							w={{ base: '80%', lg: '50%' }}
						/>
						<Box
							px={{ base: 0, lg: 20 }}
							my={{ base: 10, lg: 0 }}
							py={{ base: 8 }}
							w={{ base: '80%', lg: '50%' }}
							css={css`
								h1 {
									font-size: 3rem;
									font-weight: 700;
								}
								max-width: 75ch;
							`}
						>
							<h1>{prismicContent.title[0]!.text || 'Become a customer'}</h1>
							<p>{prismicContent.subtitle[0]!.text}</p>
							<br />
							<section className="contact">
								<div className="container py-5 ">
									<div className="row justify-content-center">
										<div className="col-12 col-md-8">
											<Box
												onSubmit={(e) => submitForm(e)}
												action="https://formspree.io/myynbvkl"
												method="POST"
												as="form"
												bg="white"
												p={8}
												rounded="6px"
											>
												{/* Form submission messages */}
												{status === 'SUCCESS' && (
													<InputWarning green>
														Thank you, we'll be in contact shortly
													</InputWarning>
												)}
												{status === 'ERROR' && (
													<InputWarning red>{error}</InputWarning>
												)}
												<div className="form-row">
													<div className="col">
														<label for="name">Your name</label>
														<StyledInput
															type="text"
															id="name"
															name="name"
															className="form-control"
															placeholder="John Malkovitch"
															required
														/>
													</div>
													<div className="col">
														<label for="business-name">Business name</label>
														<StyledInput
															type="text"
															id="business-name"
															name="business-name"
															className="form-control"
															placeholder="Business name"
														/>
													</div>
												</div>
												<div className="form-row my-4">
													<label for="inputEmail4">Email</label>
													<StyledInput
														type="email"
														className="form-control"
														id="email"
														name="email"
														placeholder="name@mail.com"
														required
													/>
												</div>
												<div className="form-row my-4">
													<label for="phoneInput">Phone</label>
													<StyledInput
														type="tel"
														className="form-control"
														id="phoneInput"
														name="phoneInput"
														placeholder="02 9214 2540"
													/>
												</div>
												<div className="form-row my-4">
													<label for="produceBudget">
														Estimated produce budget
													</label>
													<StyledInput
														placeholder="$0 to $500 per week"
														id="produceBudget"
														background="#f7fafc"
														color="#a0aec0"
														border="none"
														marginBottom="24px"
														as={Select} // to hijack StyledInput styles
													>
														<option value="$0 to $500 per week">
															$0 to $500 per week
														</option>
														<option value="$500 to $1,000 per week">
															$500 to $1,000 per week
														</option>
														<option value="$1,000 to $1,500 per week">
															$1,000 to $1,500 per week
														</option>
														<option value="$1,500 to $2,000 per week">
															$1,500 to $2,000 per week
														</option>
														<option value="$2,000 or more per week">
															$2,000 or more per week
														</option>
													</StyledInput>
												</div>
												<Button
													className="btn btn-success btn-submit btn-lg px-4 mr-3"
													type="submit"
													w="100%"
												>
													Send enquiry
												</Button>
											</Box>
										</div>
									</div>
								</div>
							</section>
						</Box>
					</Flex>
				</Box>
			</Flex>
		</PageLayout>
	);
};

export default signUp;
