import React from 'react';
import styled from '@emotion/styled';

const WarningDiv = styled.div`
	background-color: #ffdd57;
	color: #fff;
	font-size: 12px;
	border-radius: 4px;
	display: inline-flex;
	font-size: 0.75rem;
	height: 2em;
	justify-content: center;
	line-height: 1.5;
	padding-left: 0.75em;
	padding-right: 0.75em;
	white-space: nowrap;
	align-items: center;
	margin: 0.5em 0;
	margin-bottom: 0;

	h4 {
		margin-bottom: 0;
		padding: 4px 2px;
	}

	${(props) => props.red && `background: #ff3860;`}
	${(props) => props.green && `background: #4cd18e;`};
`;
export const InputWarning = ({ children, ...rest }) => {
	// const { ...rest } = props;

	return (
		children && (
			<WarningDiv {...rest}>
				<h4>{children}</h4>
			</WarningDiv>
		)
	);
};
